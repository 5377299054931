<template>
  <div>
    <b-overlay :show="isFetchingPlayers">
      <b-card>
        <b-alert
          v-if="players.dailyLimit"
          variant="success"
          show
        >
          <div class="alert-body">
            แจกเครดิตฟรีวันนี้ {{ players.todayFreeCredit | currency }} / {{ players.dailyLimit | money }} ยูส
          </div>
        </b-alert>
        <b-row align-v="center">
          <b-col>
            <h6>
              {{ `${$t('fields.result')} (${total})` }}
            </h6>
          </b-col>
          <b-col class="text-right">
            <b-button
              variant="primary"
              :to="{name: 'CREATE_PLAYER'}"
            >
              <feather-icon
                icon="PlusIcon"
              />
              {{ $t('buttons.add') }}
            </b-button>
          </b-col>
        </b-row>
        
        <b-row
          align-h="center"
          class="my-2"
        >
          <b-col>
            <b-input-group>
              <b-form-input
                v-model="search"
                type="search"
                :placeholder="`${$t('fields.search')}...(ชื่อผู้ใช้/ รหัสสมาชิก/ ชื่อ) `"
              />
            </b-input-group>
          </b-col>
          <b-col
            v-if="isOwner"
            cols="3"
          >
            <master-select-input
              :value="selectedMaster"
              hide-label
              @update="onMasterIdChange"
            />
          </b-col>
          <b-col
            v-if="isOwner || isMaster"
            cols="3"
          >
            <agent-select-input
              :value="selectedAgent"
              :master-id="selectedMaster"
              hide-label
              @update="onAgentIdChange"
            />
          </b-col>
        </b-row>
        <!-- Table -->
        <b-table
          :items="playerList"
          :fields="fields"
          responsive
          show-empty
        >
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(username)="data">
            <b-link :to="`/players/detail/${data.item.id}`">
              {{ data.value }}
            </b-link>
          </template>
          <template #cell(agent)="data">
            <span v-if="data.value.name">
              {{ data.value.name }}
            </span>
            <b-badge
              v-else
              variant="danger"
            >
              <feather-icon
                icon="XCircleIcon"
                size="20"
                class="mr-1"
              />
              {{ $t('agent.no_agent') }}
            </b-badge>
          </template>
          <template #cell(firstname)="data">
            {{ data.value }}
            {{ data.item.lastname }}
            <b-button
              v-if="data.item.note"
              size="xs"
              variant="warning"
              @click="toggleRemarkModal(data.item, true)"
            >
              !
            </b-button>
          </template>
          <template #cell(credit)="data">
            {{ data.value | currency }}
          </template>
          <template #cell(holdCredit)="data">
            {{ data.value | currency }}
          </template>
          <template #cell(createdAt)="data">
            {{ data.value | datetime }}
          </template>
          <template #cell(promotionLockStatus)="data">
            <b-badge :class="displayPromotionLockColor(data.value)">
              {{ displayPromotionLock(data.value) }}
            </b-badge>
          </template>
          <template #cell(isBanned)="data">
            <b-badge
              :variant="data.value ? 'danger' : 'success'"
              pill
            >
              {{
                data.value
                  ? 'แบน'
                  : 'ปกติ'
              }}
            </b-badge>
          </template>
          <template #cell(id)="data">
            <b-button-group size="sm">
              <!-- isFreeCredit
              false = ยังไม่เคยรับ
              ให้แสดงปุ่ม เติมเครดิตฟรี
              true = เคยรับแล้ว
              ให้เปลี่ยนจากเติมเครดิตฟรีสีเขียวเป็นสีแดง "เคยรับเครดิตฟรีแล้ว" และไม่สามารถกดได้ -->
              <b-button
                v-if="$allowPermission('agent:free-credit')"
                :variant="data.item.isFreeCredit ? (Number(data.item.totalDeposit) > 0 ? 'warning' : 'danger') : 'success'"
                :disabled="data.item.isFreeCredit"
                style="width: 110px"
                @click="toggleFreeCredit(data.item)"
              >
                {{ data.item.isFreeCredit ? (Number(data.item.totalDeposit) > 0 ? 'มียอดฝากแล้ว' : 'เคยรับเครดิตฟรีแล้ว') : 'เติมเครดิตฟรี' }}
              </b-button>
              <b-dropdown
                variant="info"
                size="sm"
                dropleft
                no-caret
              >
                <template #button-content>
                  <feather-icon
                    icon="SettingsIcon"
                    size="20"
                  />
                </template>
                <b-dropdown-item
                  v-if="$allowPermission('agent:manage.user')"
                  :to="`/players/detail/${data.item.id}`"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-1"
                  />
                  {{ $t('buttons.edit') }}
                </b-dropdown-item>
                <b-dropdown-item
                  @click="toggleDirectDepositModal(data.item)"
                >
                  <feather-icon
                    icon="PlusCircleIcon"
                    class="text-success mr-1"
                  />
                  <span class="text-success">
                    {{ $t('credits.direct_deposit') }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="toggleDirectWithdrawModal(data.item)"
                >
                  <feather-icon
                    icon="MinusCircleIcon"
                    class="text-warning mr-1"
                  />
                  <span class="text-warning">
                    {{ $t('credits.direct_withdraw') }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="unlockPromotion(data.item)"
                >
                  <feather-icon
                    icon="UnlockIcon"
                    class="text-info mr-1"
                  />
                  <span class="text-info">
                    ปลดโปรโมชั่น
                  </span>
                </b-dropdown-item>
                <b-dropdown-item :href="`/credits/all?search=${data.item.username}`">
                  <feather-icon
                    icon="FileTextIcon"
                    class="mr-1"
                  />
                  {{ $t('credits.credit_transaction') }}
                </b-dropdown-item>
                <b-dropdown-item @click="toggleRemarkModal(data.item, false)">
                  <feather-icon
                    icon="Edit3Icon"
                    class="mr-1"
                  />
                  <span>
                    {{ $t('fields.remark') }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item
                  variant="danger"
                  @click="onBanUser(data.item)"
                >
                  <feather-icon
                    icon="UserXIcon"
                    class="mr-1"
                  />
                  <span>
                    {{
                      data.item.isBanned
                        ? $t('user.cancel_ban_user')
                        : $t('user.ban_user')
                    }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </b-button-group>
          </template>
          <template #empty="">
            <p class="text-center text-muted">
              {{
                $t('messages.nothing_here')
              }}
            </p>
          </template>
          <template #cell(note)="data">
            <span v-if="data.value.length === 0">-</span>
            <b-badge v-else>
              {{ data.value }}
            </b-badge>
          </template>
        </b-table>
        <!-- pagination -->
        <b-row>
          <b-col md="4">
            <page-limit-select
              :value="limit"
              @update="onLimitChange"
            />
          </b-col>
          <b-col>
            <pagination-input
              :per-page="limit"
              :total="total"
              @update="(val) => currentPage = val"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
    <DirectWithdrawModal
      ref="directWithdrawTransfer"
      :player-id="selectedPlayer.id || ''"
      :player="selectedPlayer"
      :search="search"
      no-btn
    />
    <DirectDepositModal
      ref="directDepositTransfer"
      :player-id="selectedPlayer.id || ''"
      :player="selectedPlayer"
      :search="search"
      no-btn
      no-attach
      no-promo
      @finish="fetchData"
    />
    <RemarkForm
      :value="selectedPlayer"
      :readonly="isRemarkReadonly"
      :search="search"
      @finish="closeRemarkModal"
    />
    <FreeCreditModal
      ref="freeCreditModal"
      :player="selectedPlayer"
      :search="search"
      @fetch="fetchData"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  page: {
    title: 'Player List',
  },
  components: {
    DirectDepositModal: () =>
      import('@/views/common-forms/DirectDepositPlayerModal.vue'),
    DirectWithdrawModal: () =>
      import('@/views/common-forms/DirectWithdrawPlayerModal.vue'),
    RemarkForm: () => import('@/views/common-forms/RemarkForm.vue'),
    FreeCreditModal: () =>
      import('@/views/common-forms/FreeCreditModal.vue'),
  },
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,
      selectedPlayer: { id: ''},
      onSearchTimeout: null,
      isRemarkReadonly: false,
      fields: [
        '#',
        {
          key: 'agent',
          label: this.$t('agent.name'),
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'codeName',
          label: this.$t('user.prefix'),
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'username',
          label: this.$t('user.username'),
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'firstname',
          label: `${this.$t('user.firstname')} ${this.$t('user.lastname')}`,
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'credit',
          label: this.$t('credits.credit'),
          class: 'text-right',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'holdCredit',
          label: this.$t('fields.hold_balance'),
          class: 'text-right',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'promotionLockStatus',
          label: 'ติดโปรโมชั่น',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'createdAt',
          label: this.$t('fields.createdAt'),
          thStyle: {
            minWidth: '160px',
          },
        },
        {
          key: 'isBanned',
          label: this.$t('fields.status'),
          class: 'text-center',
        },
        {
          key: 'id',
          label: '-',
          thStyle: {
            minWidth: '200px',
          },
          class: 'text-right',
        },
      ],
      search: '',
      selectedMaster: '',
      selectedAgent: '',
    }
  },
  computed: {
    ...mapState({
      isFetchingPlayers: (state) => state.player.isFetchingPlayers,
      isUpdatingPlayerSuccess: (state) => state.player.isUpdatingPlayerSuccess
    }),
    ...mapGetters(['isOwner', 'isMaster', 'players', 'playerDetail']),
    playerList() {
      const { items } = this.players
      return items
    },
    pagination() {
      const { meta } = this.players
      return meta
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
  },
  watch: {
    currentPage(val) {
      if(val){
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    search() {
      this.delaySearch()
    },
    'selectedPlayer.id'(val){
      if(val){
        this.fetchPlayer(val)
      }
    },
    isUpdatingPlayerSuccess(val){
      if(val === true){
        this.fetchData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchPlayers', 'fetchPlayer', 'deletePlayer', 'updatePlayer', 'unlockPlayerPromotion']),
    fetchData() {
      this.fetchPlayers({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: this.search,
        agentId: this.selectedAgent,
        masterId: this.selectedMaster,
      })
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onLimitChange(limit) {
      if(limit){
        this.selectedLimit = limit
        this.fetchData()
      }
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
      this.fetchData()
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
      this.fetchData()
    },
    toggleRemarkModal(player, isReadonly) {
      this.$bvModal.show('remark-form-modal')
      this.selectedPlayer = player
      this.isRemarkReadonly = isReadonly
    },
    closeRemarkModal() {
      this.$bvModal.hide('remark-form-modal')
      this.selectedPlayer = null
      this.fetchData()
    },
    toggleDirectDepositModal(player) {
      this.selectedPlayer = player
      this.$refs.directDepositTransfer.toggleModal()
    },
    toggleDirectWithdrawModal(player) {
      this.selectedPlayer = player
      this.$refs.directWithdrawTransfer.toggleModal()
    },
    unlockPromotion(player) {
      this.$bvModal
          .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
            okTitle: `${this.$t('buttons.confirm')}`,
            cancelTitle: `${this.$t('buttons.cancel')}`,
          })
          .then((confirm) => {
            if (confirm) {
              this.unlockPlayerPromotion({
                playerId: player.id,
                search: this.search
              })
            }
          })
    },
    toggleFreeCredit(data) {
      this.$refs.freeCreditModal.toggleModal()
      this.selectedPlayer = data
    },
    onBanUser(player) {
      this.selectedPlayer = player
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.updatePlayer({
              playerId: player.id,
              data: { 
                isBanned: !player.isBanned,
                firstName: this.playerDetail.profile.firstname || player.firstname,
                lastName: this.playerDetail.profile.lastname || player.lastname,
                bankAccountNumber: this.playerDetail.bankAccount.bankAccountNumber,
                bankCode: this.playerDetail.bankAccount.bankCode,
                },
              search: this.search
            })
          }
        })
    },
    onDeleteUser(user) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.deletePlayer(user.id)
          }
        })
    },
    displayPromotionLock(status) {
      switch (status) {
        case 0:
          return 'ติดโปรเครดิตฟรี'
        case 1:
          return 'ติดเทิร์นเครดิต'
        case 2:
          return 'ติดเทิร์นโอเวอร์'
        default:
          return 'ไม่ติดโปร';
      }
    },
    displayPromotionLockColor(status) {
      switch (status) {
        case 0:
        case 1:
        case 2:
          return 'badge-soft-danger'
        default:
          return 'badge-soft-success';
      }
    },
  },
  
}
</script>
